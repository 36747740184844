import React from 'react'

const MovieDisplay = ({movie}) => {

    const loaded = () => {
        return (
            <>
                <h1>{movie.Title}</h1>
                <h2>{movie.Genre}</h2>
                <img src={movie.Poster} alt={movie.Title} />
                <p>Released: </p> <h2>{movie.Year}</h2>
                <p>this movie made a whopping: </p>
                <h2>{movie.BoxOffice}!</h2>
                <p>Critical Reception:</p>
                <h2>{movie.Ratings[0].Source}</h2>
                <h3>{movie.Ratings[0].Value}</h3>
                <h2>{movie.Ratings[1].Source}</h2>
                <h3>{movie.Ratings[1].Value}</h3>
             </>
        )
    }

    const loading = () => {
        return <h1>No Movie has been called</h1>
    }
// End Return  
    return movie ? loaded() : loading()
}

export default MovieDisplay