import { useState, useEffect } from 'react'
import './App.css'
import Form from './components/Form'
import MovieDisplay from './components/MovieDisplay'

function App() {

const API_KEY = '48a59353'
const favMovieArr = ['Primer', 'The Fall', 'Paris, Texas', 'Avatar', 'Total Recall', 'The Toxic Avenger', 'Wings of Desire']
const randomMovie = favMovieArr[Math.floor(Math.random()*favMovieArr.length)]
console.log(randomMovie);
// State to hold data
const [movie, setMovie] = useState(null)

// Function to GET from API
const getMovie = async (searchTerm) => {
  // Fetch request and store response
  const response = await fetch(
  `https://www.omdbapi.com/?apikey=${API_KEY}&t=${searchTerm}`
)
  //Parse JSON from API call
  const data = await response.json()
  setMovie(data)
}

// So that an item displays on page load
useEffect(() => {
  getMovie(randomMovie)
}, [])

  return (
    <div className="App">
      <Form getMovie={getMovie} />
      <MovieDisplay movie={movie}/>
    </div>
  );
}

export default App
