import { useState } from "react"

const Form = (props) => {
// State to hold data from form
    const [formState, setFormState] = useState({
        searchterm: "",
        })

    // Handle Change
    const handleChange = (event) => {
        setFormState({ searchterm: event.target.value})
    }

    // Handle Submit
    const handleSubmit = (event) => {
        event.preventDefault()
        props.getMovie(formState.searchterm)
    }

// END
  return (
    <div>
        <form onSubmit={handleSubmit}>
            <input
                type="text"
                name="searchterm"
                onChange={handleChange}
                value={formState.searchterm}
            />
            <input type="submit" value="submit"/>
        </form>
    </div>
  )
}

export default Form